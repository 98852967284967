import { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { AppTracker } from '@business/tracking/AppTracker';

interface TopbarProps
{

}

const Topbar: FunctionComponent<TopbarProps> = (props) =>
{
    /** Track pageview */
    useEffect(() => {
        AppTracker.instance.logPageView();
    }, []);
    
    /** Menu changes when scrolled down. */
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);

        return () => { window.removeEventListener("scroll", scrollNavigation, true); }
    }, [])

    const scrollNavigation = () => {
        let doc = document.documentElement;
        let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById('topnav')?.classList.add('nav-sticky');
        } else {
            document.getElementById('topnav')?.classList.remove('nav-sticky');
        }
    };
    
    /** Render */
    return (
        <header id="topnav" className="defaultscroll sticky">
            <Container>
                <div className="logo">
                    <>
                        <span className="logo-light-mode">
                            <img src={"img/learnlogics-logo-dark.png"} className="l-dark" height="24" alt="" />
                            <img src={"img/learnlogics-logo-light.png"} className="l-light" height="24" alt="" />
                        </span>
                        <img src={"img/learnlogics-logo-light.png"} height="24" className="logo-dark-mode" alt="" />
                    </>
                </div>
            </Container>
        </header>
    );
}

export default Topbar;