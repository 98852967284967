import { useTranslate } from '@xFrame4/common/Hooks';
import { FunctionComponent } from 'react';
import { Container, Row } from 'react-bootstrap';
import Feature, { FeatureObject } from './Feature';
import SectionTitle from './SectionTitle';

interface FeaturesProps
{

}

const Features: FunctionComponent<FeaturesProps> = () =>
{
    const t = useTranslate();
    const features: FeatureObject[] = [
        {
          icon: 'uil uil-arrow h1 text-primary',
          title: 'FEATURE_1_TITLE',
          description: 'FEATURE_1_DESCRIPTION',
        },
        {
            icon: 'uil uil-mobile-android h1 text-primary',
            title: 'FEATURE_2_TITLE',
            description: 'FEATURE_2_DESCRIPTION',
        },
        {
            icon: 'uil uil-instagram h1 text-primary',
            title: 'FEATURE_3_TITLE',
            description: 'FEATURE_3_DESCRIPTION',
        },
        {
            icon: 'uil uil-usd-square h1 text-primary',
            title: 'FEATURE_4_TITLE',
            description: 'FEATURE_4_DESCRIPTION',
        },
        {
            icon: 'uil uil-user-arrows h1 text-primary',
            title: 'FEATURE_5_TITLE',
            description: 'FEATURE_5_DESCRIPTION',
        },
        {
            icon: 'uil uil-envelopes h1 text-primary',
            title: 'FEATURE_6_TITLE',
            description: 'FEATURE_6_DESCRIPTION',
        },
    ];
    
    return (
        <section className="section">
            <Container>
                {/* section title */}
                <SectionTitle
                    title={t('FEATURES_TITLE')}
                >
                    <span dangerouslySetInnerHTML={{__html: t('FEATURES_DESCRIPTION')}}></span>
                </SectionTitle>

                {/* feature box */}
                <Row>
                    {features.map((feature, i) =>
                        <Feature 
                            key={i}
                            feature={feature}
                        />
                    )}
                </Row>
            </Container>
        </section>
    );
}

export default Features;