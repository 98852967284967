import { FunctionComponent } from 'react';
import Contact from './Contact';
import Features from './Features';
import Footer from './Footer';
import Hero from './Hero';
import Topbar from './Topbar';

interface HomePageProps
{

}

const HomePage: FunctionComponent<HomePageProps> = () =>
{
    /** Render */
    return (
        <>
            <Topbar />
            <Hero />
            <Features />
            <Contact />
            <Footer />
        </>
    );
}

export default HomePage;