import { useTranslate } from '@xFrame4/common/Hooks';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface FooterProps
{
    isLight?: boolean;
}

const Footer: FunctionComponent<FooterProps> = (props) =>
{
    const t = useTranslate();
    
    let grid1 = [
        { title: "Román Tanár", link: "https://www.romantanar.ro" },
        { title: "FarmLogics", link: "https://www.farmlogics.ro" },
        { title: "Evadeaza", link: "https://www.evadeaza.ro" },
        // { title: "Gag Donkey", link: "https://www.gagdonkey.net" },
        { title: "VoucherLogics", link: "https://www.voucherlogics.ro" },
    ];

    return (
        <>
            <footer className={props.isLight ? "footer bg-light" : "footer"}>
                <Container>
                    <Row>
                        <Col
                            lg="4"
                            xs="12"
                            className="mb-0 mb-md-4 pb-0 pb-md-2"
                            name="footercolumn"
                        >
                            <Link href="#" className="logo-footer">
                                <img
                                    src={props.isLight ? "img/learnlogics-logo-dark.png" : "img/learnlogics-logo-light.png"}
                                    height="24"
                                    alt=""
                                />
                            </Link>
                            <p className={props.isLight ? "mt-4 text-muted" : "mt-4"}>
                                {t('FOOTER_DESCRIPTION')}
                            </p>
                        </Col>

                        <Col
                            lg="2"
                            md="4"
                            xs="12"
                            className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                            name="footercolumn"
                        >
                            <h5
                                className={
                                    props.isLight
                                        ? "text-dark footer-head"
                                        : "text-light footer-head"
                                }
                            >
                                {t('PRODUCTS')}
                            </h5>
                            <ul className="list-unstyled footer-list mt-4">
                                {grid1.map((grid, key) => (
                                    <li key={key}>
                                        <Link
                                            href={grid.link}
                                            className={
                                                props.isLight ? "text-muted" : "text-foot"
                                            }
                                        >
                                            <a className="text-white" target="_blank">
                                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                                            {grid.title}
                                            </a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <footer className="footer footer-bar">
                <Container className="text-center">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="text-sm-start">
                                <p className="mb-0">
                                    © 2020 LearnLogics SRL
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default Footer;