import { useTranslate } from '@xFrame4/common/Hooks';
import { FunctionComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface HeroProps
{

}

const Hero: FunctionComponent<HeroProps> = (props) =>
{
    const t = useTranslate();

    return (
        <>
            <section
                className="bg-half-260 bg-primary d-table w-100"
                style={{ background: `url("/img/enterprise.png") center center` }}
            >
                <div className="bg-overlay"></div>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="title-heading text-center mt-4">
                                <h1 className="display-4 title-dark fw-bold text-white mb-3">
                                    {t('HERO_TITLE')}
                                </h1>
                                <p className="para-desc mx-auto text-white-75">
                                    {t('HERO_DESCRIPTION')}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default Hero;