import { useTranslate } from '@xFrame4/common/Hooks';
import { FunctionComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from './SectionTitle';

interface ContactProps
{

}

const Contact: FunctionComponent<ContactProps> = (props) =>
{
    const t = useTranslate();

    return (
        <div className="mb-5">
            <Container className="">
                {/* section title */}
                <SectionTitle
                    title={t('CONTACT_TITLE')}
                >
                    <>{t('CONTACT_DESCRIPTION')}</>
                </SectionTitle>

                <Row className="justify-content-center mt-4">
                    <Col lg="7" md="10">
                        <div className="text-center">
                            <span className="text-primary fw-bold">info@learnlogics.ro</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Contact;