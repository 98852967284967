import Tracker from '@xFrame4/common/Tracker';

export class AppTracker extends Tracker
{
    protected constructor()
    {
        super();

        this.trackWithGoogle = true;
        this.trackWithFacebook = true;
    }

    static createInstance(): AppTracker
    {
        let appTracker = new AppTracker();
        appTracker.enabled = true;

        return appTracker;
    }
}