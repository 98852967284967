import { useTranslate } from '@xFrame4/common/Hooks';
import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

interface FeatureProps
{
    feature: FeatureObject;
}

const Feature: FunctionComponent<FeatureProps> = (props) =>
{
    const t = useTranslate();
    
    return (
        <Col md={4} xs={12} name="featurebox" className="mt-5 mb-5">
            <div className="features text-center">
                <div className="image position-relative d-inline-block">
                    <i className={props.feature.icon} >
                    </i>
                </div>
                <div className="content mt-4">
                    <h5>{t(props.feature.title)}</h5>
                    <p className="text-muted mb-0">{t(props.feature.description)}</p>
                </div>
            </div>
        </Col>
    );
}

export type FeatureObject =
{
    icon: string,
    title: string,
    description: string,
}

export default Feature;